import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild{

  constructor(private authService: AuthService, private router: Router) { }

  /*
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let isAuth = this.authService.isAuthenticated();
    const user = this.authService.userValue;
    console.log(user);
    console.log(route.firstChild);
    //console.log(route.data.roles[0]);
    //console.log(route.data.roles.indexOf(user.role));

    if (route.data.roles && route.data.roles.indexOf(user.roles["ROLE_USER"]) === -1) {
        console.log(user.roles);
    }
    if (!isAuth) {
      this.router.navigate(['/pages/login']);
    }
    else {
      return true;
    }
  }  */
 
   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log('checking  route access');
    //return this.canActivateChild(route, state); 
    return true;
  } 
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log('checking child route access');
    let isAuth = this.authService.isAuthenticated();
    const user = this.authService.getuserValue();
    console.log(route.data.role);
    console.log(user.roles);
    
   // console.log(user.roles[0]);
   // console.log(route.data.role[0]);


    console.log(user.roles.includes(route.data.role));
    if (user) {

      // check if route is restricted by role
      if (route.data.role && route.data.role.indexOf(user.roles) === -1) {
          // role not authorised so redirect to home page
          this.router.navigate(['/']);
          return false;
      }

      // authorised so return true
      return true;
  }

    
    if (!isAuth) {
      this.router.navigate(['/pages/login']);
      return false;
    }
    
  }

}


