import {
  Component, OnInit, ViewChild, OnDestroy,
  ElementRef, AfterViewInit, ChangeDetectorRef, HostListener
} from "@angular/core";
import { ROUTES, ROUTES_USR, ROUTES_MOD, ROUTES_ADMIN,   ROUTES_USR_MARKETING, ROUTES_USR_SALES, ROUTES_QCUSR_SALES, ROUTES_USR_NEWPD, ROUTES_MGR_MARKETING, ROUTES_MGR_SALES, ROUTES_MGR_NEWPD, ROUTES_USR_HR, ROUTES_USR_QUALITY, ROUTES_MGR_QUALITY, ROUTES_USR_PRODUCTION, ROUTES_MGR_PRODUCTION, ROUTES_MGR_STORES, ROUTES_USR_STORES, ROUTES_USR_PURCHASE, ROUTES_MGR_PURCHASE, ROUTES_USR_PLANNING, ROUTES_USR_DELIVERYDESPATCH } from './vertical-menu-routes.config';
import { HROUTES } from '../horizontal-menu/navigation-routes.config';

import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { customAnimations } from "../animations/custom-animations";
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfigService } from '../services/config.service';
import { Subscription } from 'rxjs';
import { LayoutService } from '../services/layout.service';
import { AuthService } from '../auth/auth.service';
import { User } from '../models/user';

@Component({
  selector: "app-sidebar",
  templateUrl: "./vertical-menu.component.html",
  animations: customAnimations
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  public menuItems: any[];
  public menuArray : any[];
  level: number = 0;
  logoUrl = 'assets/img/logo.png';
  public config: any = {};
  protected innerWidth: any;
  layoutSub: Subscription;
  configSub: Subscription;
  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout;

  user: User;
  
  constructor(
    private router: Router,
    public translate: TranslateService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private authService : AuthService,
    private deviceService: DeviceDetectorService
  ) {
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.isTouchDevice();
  }


  ngOnInit() {
    this.user = this.authService.getuserValue();
    console.log(this.user.acl[0]);
    console.log(this.user.roles);
    this.menuArray=[];
    switch(this.user.roles[0]){
      case 'ROLE_USER':
                for (let i = 0; i < this.user.acl.length; i++) {
                  if (this.user.acl[i] == "Marketing")
                  this.menuArray = this.menuArray.concat(ROUTES_USR_MARKETING)
                  if (this.user.acl[i] == "Sales")
                  this.menuArray = this.menuArray.concat(ROUTES_USR_SALES)
                  if (this.user.acl[i] == "NewPD")
                  this.menuArray = this.menuArray.concat(ROUTES_USR_NEWPD)
                  if (this.user.acl[i] == "HR")
                  this.menuArray = this.menuArray.concat(ROUTES_USR_HR)
                  if (this.user.acl[i] == "Quality")
                  this.menuArray = this.menuArray.concat(ROUTES_USR_QUALITY)
                  if (this.user.acl[i] == "Production")
                  this.menuArray = this.menuArray.concat(ROUTES_USR_PRODUCTION)
                  if (this.user.acl[i] == "Stores")
                  this.menuArray = this.menuArray.concat(ROUTES_USR_STORES)
                  if (this.user.acl[i] == "Purchase")
                  this.menuArray = this.menuArray.concat(ROUTES_USR_PURCHASE)
                  if (this.user.acl[i] == "Planning")
                  this.menuArray = this.menuArray.concat(ROUTES_USR_PLANNING)
                  if (this.user.acl[i] == "Delivery & Despatch")
                  this.menuArray = this.menuArray.concat(ROUTES_USR_DELIVERYDESPATCH)

                }
                 break;
      case 'ROLE_QCUSER':
                for (let i = 0; i < this.user.acl.length; i++) {
                  if (this.user.acl[i] == "Sales")
                  this.menuArray = this.menuArray.concat(ROUTES_QCUSR_SALES)
                }
                  break;
      case 'ROLE_SUPERVISOR':
                  break;                  
      case 'ROLE_MANAGER':
                for (let i = 0; i < this.user.acl.length; i++) {
                  if (this.user.acl[i] == "Marketing")
                  this.menuArray = this.menuArray.concat(ROUTES_MGR_MARKETING)
                  if (this.user.acl[i] == "Sales")
                  this.menuArray = this.menuArray.concat(ROUTES_MGR_SALES)
                  if (this.user.acl[i] == "NewPD")
                  this.menuArray = this.menuArray.concat(ROUTES_MGR_NEWPD)
                  if (this.user.acl[i] == "Quality")
                  this.menuArray = this.menuArray.concat(ROUTES_MGR_QUALITY)
                  if (this.user.acl[i] == "Production")
                  this.menuArray = this.menuArray.concat(ROUTES_MGR_PRODUCTION)
                  if (this.user.acl[i] == "Stores")
                  this.menuArray = this.menuArray.concat(ROUTES_MGR_STORES)
                  if (this.user.acl[i] == "Purchase")
                  this.menuArray = this.menuArray.concat(ROUTES_MGR_PURCHASE)
                }
                  break;                  
      case 'ROLE_ADMIN':
                this.menuArray = ROUTES_ADMIN;
                break;
    }


    this.menuItems = this.menuArray;
  }

  ngAfterViewInit() {

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    });

    this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
      collapse => {
        if (this.config.layout.menuPosition === "Side") {
          this.collapseSidebar = collapse;
        }
      });

  }


  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
      if (this.resizeTimeout) {
          clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout((() => {
        this.innerWidth = event.target.innerWidth;
          this.loadLayout();
      }).bind(this), 500);
  }

  loadLayout() {

    if (this.config.layout.menuPosition === "Top") { // Horizontal Menu
      if (this.innerWidth < 1200) { // Screen size < 1200
        this.menuItems = this.menuArray;
      }
    }
    else if (this.config.layout.menuPosition === "Side") { // Vertical Menu{
      this.menuItems = this.menuArray;
    }




    if (this.config.layout.sidebar.backgroundColor === 'white') {
      this.logoUrl = 'assets/img/logo-dark.png';
    }
    else {
      this.logoUrl = 'assets/img/logo.png';
    }

    if(this.config.layout.sidebar.collapsed) {
      this.collapseSidebar = true;
    }
    else {
      this.collapseSidebar = false;
    }
  }

  toggleSidebar() {
    let conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({ layout: conf.layout });

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent("HTMLEvents");
    evt.initEvent("resize", true, false);
    window.dispatchEvent(evt);
  };

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {

    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    }
    else {
      this.perfectScrollbarEnable = true;
    }

  }


  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }

  }

}
