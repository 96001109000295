import { RouteInfo } from './vertical-menu.metadata';
import { CompanyCreateComponent } from 'app/pages/full-pages/company/company-create/company-create.component';
import { AuthGuard } from '../auth/auth-guard.service';




//Functional Access Profile - Modules-wise

export const ROUTES_ADMIN: RouteInfo[] = [
  {
    path: '',  title: 'Admin', icon: 'ft-settings  submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/admin/dashboard-admin', title: 'Dashboard', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/admin/settings', title: 'Settings', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/admin/users-create', title: 'Add User', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/admin/users-list', title: 'List Users', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/admin/users-view/1', title: 'View User', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/admin/users-edit/1', title: 'Edit Users', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    ]
  },
  { path: '/pages/controlpanel/control-panel', title: 'Control Panel', icon: 'ft-settings', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  {
    path: '', title: 'My Tickets', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/crm/incident-create', title: 'Create Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/crm/incident-list', title: 'List Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/crm/incident-view/1', title: 'View Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    ]
  },

  {
    path: '',  title: 'Incident', icon: 'ft-settings  submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/crm/incident-create', title: 'Create', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/crm/incident-list', title: 'List', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/crm/incidentdetail-create/1', title: 'Incident Detail', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ],
    
  },
  {
    path: '',  title: 'Problem', icon: 'ft-settings  submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/crm/problem-create', title: 'Create', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/crm/problem-list', title: 'List', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/crm/problemdetail-create/1', title: '8D Anaylsis', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/crm/problem-actions', title: 'Problem Actions', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ],
    
  },

  { path: '/pages/qms/qms-settings', title: 'QMS Settings', icon: 'ft-settings', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

  {
    path: '',  title: 'Sales', icon: 'ft-settings  submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/sales/kpidashboard', title: 'KPI Dashboard', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ],
    
  },
  {
    path: '',  title: 'Products', icon: 'ft-settings  submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/products/product-list', title: 'Product List', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/products/product-view/1', title: 'Product View', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/products/product-gallery', title: 'Product Gallery', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/products/controlplan-view/1', title: 'Product Control Plan', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    ],
    
  },
  {
    path: '',  title: 'Machines', icon: 'ft-settings  submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/machines/create', title: 'Register', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/machines/machine-list', title: 'Machine List', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    ],
    
  },
  {
    path: '',  title: 'Instruments', icon: 'ft-settings  submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/instruments/instrument-create', title: 'Register', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/instruments/instrument-list', title: 'Instrument List', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    ],
    
  },
  {
    path: '',  title: 'Consumables', icon: 'ft-settings  submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/consumables/consumables-create', title: 'Register', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/consumables/consumables-list', title: 'Consumables List', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ],
    
  },
  {
    path: '',  title: 'Inventory', icon: 'ft-settings  submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/stores/material-list', title: 'Material List', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/stores/material-view/1', title: 'Material View', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    ],
    
  },
  {
    path: '',  title: 'Planning', icon: 'ft-settings  submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/planning/plan-create', title: 'Plan Create', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ],
    
  },

];



export const ROUTES_USR_MARKETING: RouteInfo[] = [

  {
    path: '', title: 'Marketing', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/turtlediagram', title: 'Turtle Diagram', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/orgchart', title: 'Org Chart', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/hr/jobdescription-list', title: 'List JD', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/qms/documentworkflow', title: 'Document Workflow', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '', title: 'Enquiry', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
      submenu: [
        { path: '/pages/marketing/enquiry-create', title: 'Create Enquiry', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/pages/marketing/enquiry-list', title: 'List Enquiry', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      
      ]
      },
      { path: '', title: 'Customer', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
      submenu: [
        { path: '/pages/customers/create-customer', title: 'Register Customer', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/pages/customers/list-customers', title: 'List Customer', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
      },
      { path: '', title: 'Products', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
      submenu: [
        { path: '/pages/products/product-list', title: 'Products', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
      },

      { path: '', title: 'Facilities', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
      submenu: [
        { path: '/pages/machines/machine-list', title: 'Machines', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
      },
      { path: '', title: 'MyOrders', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
      submenu: [
        { path: '/pages/sales/listSO', title: 'List Sales Orders', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      ]
      },
      { path: '', title: 'MyPayments', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
      submenu: [
        { path: '/pages/payments/pending-invoices', title: 'List Payments', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
      },

    ]
  },

  {
    path: '', title: 'My Tickets', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/crm/incident-create', title: 'Create Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/crm/incident-list', title: 'List Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    ]
  },
];

export const ROUTES_MGR_MARKETING: RouteInfo[]=[
  {
    path: '', title: 'Marketing', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/qms/documentworkflow/approve', title: 'Document Workflow', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/hr/jobdescription-list', title: 'List JD', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

      { path: '/pages/products/product-list', title: 'Products', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/company/company-create', title: 'Facilities', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/marketing/enquiry-create', title: 'Create Enquiry', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/marketing/enquiry-list', title: 'List Enquiry', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/marketing/enquiry-view/1', title: 'View Enquiry', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/marketing/enquiry-edit/1', title: 'Edit Enquiry', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

      { path: '/pages/company/company-create', title: 'Followup', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/company/company-create', title: 'Register Customer', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/company/company-create', title: 'My Orders', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'Strategic Planning', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/marketing/orgtarget-create', title: 'Budget', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/marketing/orgtarget-list', title: 'History', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '',  title: 'Products', icon: 'ft-settings  submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/products/product-list', title: 'Product List', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ],
    
  },

];




export const ROUTES_USR_SALES: RouteInfo[] = [
  {
    path: '', title: 'Sales', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      

      { path: '/pages/turtlediagram', title: 'Turtle Diagram', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/orgchart', title: 'Org Chart', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/hr/jobdescription-list', title: 'List JD', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

      { path: '/pages/qms/documentworkflow', title: 'Document Workflow', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

      { path: '/pages/marketing/enquiry-create', title: 'Create Enquiry', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/marketing/enquiry-list', title: 'List Enquiry', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
   
      { path: '/pages/sales/createSO', title: 'Direct Sales Order', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/sales/listSO', title: 'List Sales Order', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/sales/dashboard', title: 'Sales Dashboard', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

      { path: '/pages/products/product-list', title: 'Products', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      

    ]
  },
  {
    path: '', title: 'My Tickets', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/crm/incident-create', title: 'Create Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/crm/incident-list', title: 'List Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    ]
  },
  {
    path: '', title: 'My Invoices', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/payments/pending-invoices', title: 'Pending Invoices', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    ]
  },

  {
    path: '', title: 'My Payments', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/payments/pending-invoices', title: 'Pending Payments', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    ]
  },

];


export const ROUTES_QCUSR_SALES: RouteInfo[] = [
  {
    path: '', title: 'Sales', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [

      { path: '/pages/marketing/enquiry-create', title: 'Create Enquiry', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/marketing/enquiry-list', title: 'List Enquiry', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
   
      { path: '/pages/products/product-list', title: 'Products', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/products/product-list', title: 'Sales Order', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/products/product-list', title: 'Direct Sales Order', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'My Tickets', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/crm/incident-create', title: 'Create Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/crm/incident-list', title: 'List Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    ]
  },
  {
    path: '', title: 'My Invoices', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/crm/incident-create', title: 'Create Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/crm/incident-list', title: 'List Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    ]
  },
  { path: '/pages/controlpanel/control-panel', title: 'My Expenses', icon: 'ft-settings', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  { path: '/pages/controlpanel/control-panel', title: 'C.A.P.A.', icon: 'ft-settings', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

   
];

export const ROUTES_MGR_SALES: RouteInfo[] = [
  {
    path: '', title: 'Sales', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [

      { path: '/pages/turtlediagram', title: 'Turtle Diagram', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/orgchart', title: 'Org Chart', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/qms/documentworkflow/approve', title: 'Document Workflow', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

      { path: '/pages/marketing/enquiry-create', title: 'Create Enquiry', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/marketing/enquiry-list', title: 'List Enquiry', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
   
      { path: '/pages/products/product-list', title: 'Products', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/products/product-list', title: 'Sales Order', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/products/product-list', title: 'Direct Sales Order', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

      

    ]
  },
  {
    path: '', title: 'My Tickets', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/crm/incident-create', title: 'Create Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/crm/incident-list', title: 'List Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    ]
  },
  {
    path: '', title: 'My Invoices', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/crm/incident-create', title: 'Create Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/crm/incident-list', title: 'List Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    ]
  },

  {
    path: '', title: 'My Expenses', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/crm/incident-create', title: 'Create Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/crm/incident-list', title: 'List Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    ]
  },

];


export const ROUTES_USR_NEWPD: RouteInfo[] = [

  {
    path: '', title: 'NewPD', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/turtlediagram', title: 'Turtle Diagram', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/orgchart', title: 'Org Chart', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/hr/jobdescription-list', title: 'List JD', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/qms/documentworkflow', title: 'Document Workflow', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/newpd/npdproject-create', title: 'New Project', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/newpd/npdproject-list', title: 'List Projects', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/newpd/pfd/1', title: 'Register PFD', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


      
    ]
  },
  {
    path: '',  title: 'Products', icon: 'ft-settings  submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/products/product-list', title: 'Product List', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/products/product-view/1', title: 'Product View', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/products/product-gallery', title: 'Product Gallery', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/products/controlplan-view/1', title: 'Product Control Plan', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    ]
    
  },
  {
    path: '', title: 'My Tickets', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/crm/incident-create', title: 'Create Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/crm/incident-list', title: 'List Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    ]
  },
  
];

export const ROUTES_MGR_NEWPD: RouteInfo[] =[
  {
    path: '', title: 'NewPD', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/newpd/npdproject-list', title: 'List Projects', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/hr/jobdescription-list', title: 'List JD', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/qms/documentworkflow/approve', title: 'Document Workflow', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    ]
  },
  {
    path: '', title: 'My Tickets', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/crm/incident-create', title: 'Create Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/crm/incident-list', title: 'List Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    ]
  },

];

export const ROUTES_USR_HR: RouteInfo[] =[
  {
    path: '', title: 'HR', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [

      { path: '/pages/turtlediagram', title: 'Turtle Diagram', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/orgchart', title: 'Org Chart', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/hr/jobdescription-create', title: 'Define JD', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/hr/jobdescription-list', title: 'List JD', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/qms/documentworkflow', title: 'Document Workflow', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    

    ]
  },
  {
    path: '', title: 'Post Resume', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/candidate/candidate-create', title: 'Register Candidate', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/candidate/candidate-list', title: 'List Candidates', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    ]
  },
  {
    path: '', title: 'My Tickets', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/crm/incident-create', title: 'Create Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/crm/incident-list', title: 'List Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    ]
  },
  {
    path: '', title: 'Training', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/hr/trainingcalandar-create', title: 'Schedule Training', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/hr/trainingcalandar-list', title: 'Training List', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/hr/trainingstatus-update', title: 'Training Status', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    ]
  },
  {
    path: '', title: 'Employee', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/hr/employee-create', title: 'Register', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/hr/employee-list', title: 'List', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    ]
  },



];

export const ROUTES_USR_QUALITY: RouteInfo[]=[
  {
    path: '', title: 'Quality', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [

      { path: '/pages/turtlediagram', title: 'Turtle Diagram', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/orgchart', title: 'Org Chart', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/hr/jobdescription-list', title: 'List JD', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/qms/documentworkflow', title: 'Document Workflow', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

      

    ]
  },
  {
    path: '', title: 'Audits', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/qms/audit-create', title: 'Schedule Audit ', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/qms/audit-list', title: 'List Audits', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    ]
  },
  {
    path: '', title: 'Auditors', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/qms/auditors-create', title: 'Register ', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/qms/auditors-list', title: 'List Auditors', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    ]
  },
  {
    path: '', title: 'My Tickets', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/crm/incident-create', title: 'Create Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/crm/incident-list', title: 'List Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    ]
  },
];

export const ROUTES_MGR_QUALITY: RouteInfo[]=[
  {
    path: '', title: 'Quality', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [

      { path: '/pages/turtlediagram', title: 'Turtle Diagram', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/orgchart', title: 'Org Chart', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/hr/jobdescription-list', title: 'List JD', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/qms/documentworkflow/approve', title: 'Document Workflow', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    

    ]
  },
  {
    path: '', title: 'My Tickets', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/crm/incident-create', title: 'Create Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/crm/incident-list', title: 'List Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    ]
  },
];


export const ROUTES_USR_PRODUCTION: RouteInfo[]=[
  {
    path: '', title: 'Production', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [

      { path: '/pages/turtlediagram', title: 'Turtle Diagram', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/orgchart', title: 'Org Chart', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/hr/jobdescription-list', title: 'List JD', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/qms/documentworkflow', title: 'Document Workflow', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/production/machinekiosk', title: 'Machines Kiosk', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/planning/machinecalander', title: 'Machine Calendar', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/planning/jobcalander', title: 'Job Calendar', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    ]
  },
  {
    path: '', title: 'My Tickets', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/crm/incident-create', title: 'Create Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/crm/incident-list', title: 'List Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    ]
  },
];

export const ROUTES_MGR_PRODUCTION: RouteInfo[]=[
  {
    path: '', title: 'Production', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [

      { path: '/pages/turtlediagram', title: 'Turtle Diagram', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/orgchart', title: 'Org Chart', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/hr/jobdescription-list', title: 'List JD', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/qms/documentworkflow/approve', title: 'Document Workflow', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    

    ]
  },
  {
    path: '', title: 'My Tickets', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/crm/incident-create', title: 'Create Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/crm/incident-list', title: 'List Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    ]
  },
];


export const ROUTES_USR_STORES: RouteInfo[]=[
  {
    path: '', title: 'Stores & Stock Mgmnt', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [

      { path: '/pages/turtlediagram', title: 'Turtle Diagram', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/orgchart', title: 'Org Chart', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/hr/jobdescription-list', title: 'List JD', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/qms/documentworkflow', title: 'Document Workflow', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

      

    ]
  },
  {
    path: '', title: 'Items Master', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [

      { path: '/pages/storestocks/itemmaster-create', title: 'Register Item', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/storestocks/itemmaster-list', title: 'List of Items', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      

    ]
  },
  {
    path: '', title: 'Goods Received Note (GRN)', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [

      { path: '/pages/stores/grn-create', title: 'New GRN', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/stores/grn-list', title: 'List GRN', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/stores/grn-edit/1', title: 'Edit GRN', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/stores/grn-view/1', title: 'View GRN', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

      

    ]
  },
  {
    path: '', title: 'My Tickets', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/crm/incident-create', title: 'Create Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/crm/incident-list', title: 'List Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    ]
  },
];

export const ROUTES_MGR_STORES: RouteInfo[]=[
  {
    path: '', title: 'Quality', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [

      { path: '/pages/turtlediagram', title: 'Turtle Diagram', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/orgchart', title: 'Org Chart', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/hr/jobdescription-list', title: 'List JD', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/qms/documentworkflow/approve', title: 'Document Workflow', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    

    ]
  },
  {
    path: '', title: 'My Tickets', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/crm/incident-create', title: 'Create Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/crm/incident-list', title: 'List Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    ]
  },
];


export const ROUTES_USR_PURCHASE: RouteInfo[]=[
  {
    path: '', title: 'Purchase', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [

      { path: '/pages/turtlediagram', title: 'Turtle Diagram', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/orgchart', title: 'Org Chart', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/hr/jobdescription-list', title: 'List JD', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/qms/documentworkflow', title: 'Document Workflow', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

      

    ]
  },
  {
    path: '', title: 'My Tickets', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/crm/incident-create', title: 'Create Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/crm/incident-list', title: 'List Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    ]
  },
];

export const ROUTES_MGR_PURCHASE: RouteInfo[]=[
  {
    path: '', title: 'Purchase', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [

      { path: '/pages/turtlediagram', title: 'Turtle Diagram', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/orgchart', title: 'Org Chart', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/hr/jobdescription-list', title: 'List JD', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/qms/documentworkflow/approve', title: 'Document Workflow', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    

    ]
  },
  {
    path: '', title: 'My Tickets', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/crm/incident-create', title: 'Create Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/crm/incident-list', title: 'List Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    ]
  },
];

export const ROUTES_USR_PLANNING: RouteInfo[]=[
  {
    path: '', title: 'Planning', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [

      { path: '/pages/turtlediagram', title: 'Turtle Diagram', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/orgchart', title: 'Org Chart', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/hr/jobdescription-list', title: 'List JD', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/qms/documentworkflow', title: 'Document Workflow', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/planning/machinecalander', title: 'Machine Calendar', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/planning/jobcalander', title: 'Job Calendar', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/planning/plan-create', title: 'Create Plan', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/planning/plan-request', title: 'Plan Requests', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

            

    ]
  },
  {
    path: '',  title: 'Machines', icon: 'ft-settings  submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/machines/create', title: 'Register', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/machines/machine-list', title: 'Machine List', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    ],
    
  },
  {
    path: '', title: 'My Tickets', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/crm/incident-create', title: 'Create Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/crm/incident-list', title: 'List Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    ]
  },
];


export const ROUTES_USR_DELIVERYDESPATCH: RouteInfo[]=[
  {
    path: '', title: 'DeliveryDespatch', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [

      { path: '/pages/turtlediagram', title: 'Turtle Diagram', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/orgchart', title: 'Org Chart', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/hr/jobdescription-list', title: 'List JD', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/qms/documentworkflow', title: 'Document Workflow', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/planning/machinecalander', title: 'Machine Calendar', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/planning/jobcalander', title: 'Job Calendar', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/planning/plan-create', title: 'Create Plan', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/planning/plan-request', title: 'Plan Requests', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

            

    ]
  },
  {
    path: '',  title: 'Machines', icon: 'ft-settings  submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/machines/create', title: 'Register', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/machines/machine-list', title: 'Machine List', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    ],
    
  },
  {
    path: '', title: 'My Tickets', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/crm/incident-create', title: 'Create Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/crm/incident-list', title: 'List Incident', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },


    ]
  },

];










//Sidebar menu Routes and data
export const ROUTES_USR: RouteInfo[] = [
  {
    path: '', title: 'Company', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/company/company-create', title: 'Register', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/company/company-list', title: 'List', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/company/company-view/1', title: 'View', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'Candidates', icon: 'ft-user submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/candidate/candidate-create', title: 'Register Candidate', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/candidate/candidate-list', title: 'List Candidates', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'Vacancices', icon: 'ft-layers submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/job/job-create', title: 'Post a Job', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/job/job-list', title: 'Job List', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/job/job-upload', title: 'Upload Job List', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'Interviews', icon: 'ft-users submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
 //     { path: '/pages/interview/interview-create', title: 'Schedule', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/interview/interview-list', title: 'List', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/interview/interview-callletter', title: 'Call Letter Queue', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'Invoices & Payments', icon: 'ft-file submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/invoicesandpayments/invoice-create', title: 'Create Invoice', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/invoicesandpayments/invoice-list', title: 'Invoices List', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  
];



export const ROUTES: RouteInfo[] = [
    
  {
    path: '',  title: 'Admin', icon: 'ft-settings  submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/admin/dashboard-admin', title: 'Dashboard', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/admin/settings', title: 'Settings', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/admin/users-create', title: 'Add User', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/admin/users-list', title: 'List Users', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/admin/users-view/1', title: 'View User', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/admin/users-edit/1', title: 'Edit Users', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'Company', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/company/company-create', title: 'Register', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/company/company-list', title: 'List', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/company/company-view/1', title: 'View', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/company/company-edit/1', title: 'Edit', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'Candidates', icon: 'ft-user submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/candidate/candidate-create', title: 'Register Candidate', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/candidate/candidate-list', title: 'List Candidates', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/candidate/candidate-edit/1', title: 'Edit Candidate', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'Vacancices', icon: 'ft-layers submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/job/job-create', title: 'Post a Job', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/job/job-list', title: 'Job List', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/job/job-upload', title: 'Upload Job List', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'Interviews', icon: 'ft-users submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
 //     { path: '/pages/interview/interview-create', title: 'Schedule', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/interview/interview-list', title: 'List', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/interview/interview-approve', title: 'Approve', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/interview/interview-edit/1', title: 'Edit', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/interview/interview-view', title: 'View', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/interview/interview-edit/1/feedback', title: 'Feedback', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/interview/interview-edit/1/feedback/benefits', title: 'Benefits', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/interview/invoice-list', title: 'Create Invoice', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'Invoices & Payments', icon: 'ft-file submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/invoicesandpayments/invoice-create', title: 'Create Invoice', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/invoicesandpayments/invoice-list', title: 'Payment Status', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/invoicesandpayments/invoice-view/1', title: 'View Invoice', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/invoicesandpayments/invoice-edit/1', title: 'Edit Invoice', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
];




export const ROUTES_MOD: RouteInfo[] = [
  {
    path: '', title: 'Company', icon: 'ft-life-buoy submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/company/company-create', title: 'Register', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/company/company-list', title: 'List', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/company/company-view/1', title: 'View', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'Candidates', icon: 'ft-user submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/candidate/candidate-create', title: 'Register Candidate', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/candidate/candidate-list', title: 'List Candidates', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'Vacancices', icon: 'ft-layers submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/job/job-create', title: 'Post a Job', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/job/job-list', title: 'Job List', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/job/job-upload', title: 'Upload Job List', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'Interviews', icon: 'ft-users submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
 //     { path: '/pages/interview/interview-create', title: 'Schedule', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/interview/interview-list', title: 'List', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/interview/interview-approve', title: 'Approve', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/interview/interview-callletter', title: 'Call Letter Queue', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'Invoices & Payments', icon: 'ft-file submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/pages/invoicesandpayments/invoice-create', title: 'Create Invoice', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/invoicesandpayments/invoice-list', title: 'Invoices List', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },

];




