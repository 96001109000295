import { Component, OnInit, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { HROUTES, HROUTES_ADMIN } from './navigation-routes.config';
import { LayoutService } from '../services/layout.service';
import { ConfigService } from '../services/config.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ROUTES_USR, ROUTES_MOD, ROUTES_ADMIN } from '../vertical-menu/vertical-menu-routes.config';
import { AuthService } from '../auth/auth.service';
import { User } from '../models/user';


@Component({
  selector: 'app-horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrls: ['./horizontal-menu.component.scss']
})
export class HorizontalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  public menuItems: any[];
  public menuArray: any[];
  public config: any = {};
  level: number = 0;
  transparentBGClass = "";
  menuPosition = 'Side';
  user: User;
  layoutSub: Subscription;

  constructor(private layoutService: LayoutService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private router: Router) {
    this.config = this.configService.templateConf;
  }

  ngOnInit() {

    this.user = this.authService.getuserValue();
    
    switch(this.user.roles[0]){
      case 'ROLE_USER':
                this.menuArray = ROUTES_USR;
                break;
      case 'ROLE_MODERATOR':
                  this.menuArray = ROUTES_MOD;
                  break;
      case 'ROLE_ADMIN':
                this.menuArray = ROUTES_ADMIN;
                break;
    }


    this.menuItems = this.menuArray;

   this.menuItems = HROUTES_ADMIN;
  }

  ngAfterViewInit() {

    this.layoutSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    })
  }

  loadLayout() {

    if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() != "") {
      this.menuPosition = this.config.layout.menuPosition;
    }


    if (this.config.layout.variant === "Transparent") {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    }
    else {
      this.transparentBGClass = "";
    }

  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

}
