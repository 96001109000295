import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
//import { User } from 'firebase';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { tokenName } from '@angular/compiler';
import { User } from '../models/user';

@Injectable()
export class AuthService {
  private user: Observable<User>;
  private userSubject: BehaviorSubject<User>;


  constructor(private router: Router, private http: HttpClient) {
    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    this.user = this.userSubject.asObservable();
    

}

public getuserValue(): User {
  return this.userSubject.value;
}

/*  private userDetails: firebase.User = null;

  constructor(public _firebaseAuth: AngularFireAuth, public router: Router) {
    this.user = _firebaseAuth.authState;
    this.user.subscribe(
      (user) => {
        if (user) {
          this.userDetails = user;
        }
        else {
          this.userDetails = null;
        }
      }
    );

  } */

  
  
  signupUser(email: string, password: string) {
    
  }

  signinUser(username: string, password: string) {
    //your code for checking credentials and getting tokens for for signing in user
    // return this._firebaseAuth.signInWithEmailAndPassword(email, password)

    console.log("signuser :" + username);

/*
    //uncomment above firebase auth code and remove this temp code
    return new Promise(function(resolve, reject) {
      setTimeout(function() {
        resolve(true);
      }, 1000);
    });
    */
   return this.http.post<any>(`${environment.apiUrl}/api/auth/signin`,{username,password})
   .pipe(map(user => {
       // store user details and jwt token in local storage to keep user logged in between page refreshes
      console.log("Return:" + JSON.stringify(user));
       localStorage.setItem('user', JSON.stringify(user));
       this.userSubject.next(user);
       return user;
   }));

  }

  logout() {
 //   this._firebaseAuth.signOut();
    this.router.navigate(['YOUR_LOGOUT_URL']);
  }

  isAuthenticated() {
    return true;
  }
}


