import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileName'
})
export class ShowFileName implements PipeTransform {
  transform(attachment: string): string {
    let splitArr;    
    splitArr = attachment.split('/');
    return splitArr[splitArr.length - 1];
  }
}
