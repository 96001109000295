import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ISODate'
})
export class DatePipe implements PipeTransform {
  transform(dateStr: string): string {
    return new Date(dateStr).toLocaleString();
  }
}
