export class Utility {
    public static convertDate(date: Date): string {
        return (date.getFullYear().toString() + '-' 
       + ("0" + (date.getMonth() + 1)).slice(-2) + '-' 
       + ("0" + (date.getDate())).slice(-2))
       + 'T' + date.toTimeString().slice(0,5);
    }
    public static getFileNameFromFullPath(path: string) : any {
        let splitArr;    
        splitArr = path.split('/');
        const fileName  = splitArr[splitArr.length - 1];
        splitArr.pop();
        const folderPath = splitArr.join('/') + '/';
        return {fileName, folderPath};
    }
	public static currencyFormatter(value): string {
    	if(!value || isNaN(value)) {
    		return '0';
    	}
    	let x = value;
	    x = x.toString();
	    let afterPoint = '';
	    if(x.indexOf('.') > 0){
	       afterPoint = x.substring(x.indexOf('.'),x.length);
	    }

	    x = Math.floor(x);
	    x = x.toString();
	    var lastThree = x.substring(x.length-3);
	    var otherNumbers = x.substring(0,x.length-3);
	    if(otherNumbers != ''){
	       lastThree = ',' + lastThree;
	    }
	    return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
	}
}